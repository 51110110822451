<template>
  <section class="best-wrapper">
    <!-- <h1 v-if="$route.name === 'index'">Аниме онлайн, комиксы манга и ранобэ новеллы</h1>
    <h2 v-else>Аниме онлайн, комиксы манга и ранобэ новеллы</h2> -->
    <div class="best-block">
      <div class="best-list">
        <div v-if="!bestItems.length" class="action">
          <div>
            <img
              :data-src="'/static_files/bg/123.png'"
              src="/static_files/no-poster.png"
              class="lazyload"
              alt="Аниме онлайн"
            >
          </div>
          <div>
            <div class="h3">Давай подберем, что будем смотреть / читать?</div>
            <p>
              Настрой фильтры и жми
              <strong
                @click="goTo"
                class="mini"
              >
                Подобрать
              </strong>
            </p>
          </div>
        </div>
        <div v-else>
          <div class="items">
            <article
              v-for="item in bestItems"
              :key="`index-best-item-${bestType}-${item.id}`"
              class="item"
            >
              <div class="poster">
                <img
                :data-src="item.poster"
                :alt="item.title"
                src="/static_files/no-poster.png"
                class="lazyload"
              >
              </div>
              <div class="body">
                <h2>{{ item.title }}</h2>
                <div class="description" v-html="item.description" />
              </div>
              <nuxt-link :to="`/${
                bestType.replace('db', 'watch')
                }/${
                postUrl(item.id, item.title)
                }`"
                class="link"
              >{{ item.title }}</nuxt-link>
            </article>
          </div>
          <div
            v-if="bestItems.length >= 10"
            @click="getBestItems(++bestPage)"
            class="show-more"
          >
            <span>Показать еще</span>
          </div>
          <div
            @click="hideMore"
            class="hide-more"
          >
            <span>Скрыть</span>
          </div>
        </div>
      </div>
      <div class="best-filters">
        <label for="type">
          <select id="type" v-model="bestType">
            <option value="db">Аниме</option>
            <option value="comics">Комиксы</option>
            <option value="ranobes">Ранобэ</option>
          </select>
        </label>
        <label for="genre">
          <select id="genre" v-model="bestGenre">
            <option value="">
              Жанр
            </option>
            <option
              v-for="genre in bestGenres[bestType]"
              :key="`best-genre-${genre}`"
              :value="genre"
            >
              {{ genre }}
            </option>
          </select>
        </label>
        <label for="year">
          <select id="year" v-model="bestYear">
            <option value="">
              Год
            </option>
            <option
              v-for="year in bestYears"
              :key="`best-year-${year}`"
              :value="year"
            >
              {{ year }}
            </option>
          </select>
        </label>
        <!-- <div>
          <button @click="goTo">Подобрать</button>
        </div> -->
      </div>
    </div>
  </section>
</template>

<script>
export default {
  watch: {
    bestType() {
      this.bestGenre = ''
      this.bestPage = 1
      this.bestItems = []
    },
    bestGenre() {
      this.bestPage = 1
      this.bestItems = []
    },
    bestYear() {
      this.bestPage = 1
      this.bestItems = []
    }
  },
  data() {
    const bestYears = []

    for(let i = new Date().getFullYear(); i > new Date().getFullYear() - 10; i--) {
      bestYears.push(i)
    }

    return {
      bestItems: [],
      bestType: 'db',
      bestGenres: {
        db: ["Безумие","Боевые искусства","Вампиры","Военное","Гарем","Демоны","Детектив","Детское","Дзёсей","Драма","Игры","Исторический","Комедия","Космос","Магия","Машины","Меха","Музыка","Пародия","Повседневность","Полиция","Приключения","Психологическое","Романтика","Самураи","Сверхъестественное","Сейнен","Спорт","Супер сила","Сёдзё","Сёдзё-ай","Сёнен","Сёнен-ай","Триллер","Ужасы","Фантастика","Фэнтези","Хентай","Школа","Экшен","Этти","Яой"],
        comics: [
          'боевые искусства', 'боевик', 'вампиры', 'гарем', 'гендерная интрига', 'героическое фэнтези', 'додзинси',
          'дзёсэй', 'драма', 'детектив', 'игра', 'история', 'киберпанк', 'комедия', 'мистика',
          'меха', 'махо-сёдзё', 'научная фантастика', 'перерождение', 'повседневность', 'приключения',
          'психология', 'романтика', 'самурайский боевик', 'сборник', 'сверхъестественное', 'сёдзё',
          'сёдзё для взрослых', 'сёдзё-ай', 'сёнэн', 'сёнэн-ай', 'спокон', 'сэйнэн', 'спорт', 'трагедия',
          'триллер', 'ужасы', 'фантастика', 'фэнтези', 'школа', 'эротика', 'этти', 'юри', 'яой'
        ],
        ranobes: [
          'Боевые искусства', 'Гарем', 'Гендер бендер', 'Дзёсэй', 'Для взрослых', 'Драма', 'Исторический', 'Комедия', 'Лоликон',
          'Меха', 'Милитари', 'Мистика', 'Научная фантастика', 'Непристойность', 'Повседневность', 'Приключение',
          'Психология', 'Романтика', 'Сверхъестественное', 'Спорт', 'Сэйнэн', 'Сюаньхуа', 'Сянься', 'Сёдзё', 'Сёдзё-ай',
          'Сёнэн', 'Сёнэн-ай', 'Трагедия', 'Триллер', 'Ужасы', 'Уся', 'Фэнтези', 'Школьная жизнь', 'Экшн', 'Эччи', 'Юри', 'Яой'
        ]
      },
      bestGenre: '',
      bestYear: '', // new Date().getFullYear(),
      bestYears,
      bestPage: 1
    }
  },
  methods: {
    hideMore() {
      this.bestPage = 1
      this.bestItems = []
      this.$nextTick(() => {
        const elem = document.querySelector('.need-friends .wrapper .body')
        if (elem) elem.scrollIntoView()
      })
    },
    async getBestItems(page) {
      const body = {
        type: this.bestType,
        genre: this.bestGenre,
        year: this.bestYear,
        page
      }
      const { data } = await this.$axios.post(`/api/best-block`, body)
      if (!data.error) {
        if (this.bestPage > 1) {
          const items = this.bestItems.concat(data)
          this.bestItems = items
        } else {
          this.bestItems = data
        }
        this.$nextTick(() => this.lazyLoadImgs())
      }
    },
    goTo() {
      let url = `/${this.bestType === 'db' ? 'watch' : this.bestType }?page=1`
      if (this.bestGenre) url += `&genre=${this.bestGenre}`
      if (this.bestYear) url += `&year=${this.bestYear}`
      this.$router.push(url)
    }
  }
}
</script>

<style src="~/assets/best.styl" lang="stylus" />
